<template>
  <v-row
    class="cw-checkbox-group mb-0 mb-md-4"
  >
    <v-col class="pb-0" cols="12">
      <h3 class="text-body-2">
        {{ title }}
      </h3>
      <div class="cw-application--error">
        <v-slide-y-transition origin="center top">
          <div
            v-if="!valid"
            :id="`errors--${fieldName}`"
            v-translate
            class="v-messages__message error--text"
          >
            Choose at least one option
          </div>
        </v-slide-y-transition>
      </div>
    </v-col>
    <v-col
      v-for="item in items"
      :key="item.label"
      cols="12"
      sm="6"
      md="3"
      class="pb-2"
    >
      <cw-checkbox
        :id="item.name"
        v-model="form[item.name]"
        :events="events"
        :name="fieldName"
        :rules="`requireOne:${selectionCount}`"
        class="mt-0"
        color="purple-one darken-2"
        hide-details
        @group-change="submit()"
      >
        <template #label>
          <span data-test>
            {{ item.label }}
          </span>
        </template>
      </cw-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CwCheckboxGroup',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
  },

  props: {
    events: {
      type: [String, Array],
      default: 'change',
    },

    form: {
      type: Object,
      default: () => ({}),
      required: true,
    },

    items: {
      type: Array,
      default: () => [],
      required: true,
    },

    title: {
      type: String,
      default: '',
    },

    valid: {
      type: Boolean,
      default: true,
      required: true,
    },

    // must be included in props
    value: {
      type: null,
      default: null,
      required: true,
    },
  },

  data: () => ({
    modelValue: '',
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
    }),

    fieldName() {
      return this.$attrs.id;
    },

    initialValue() {
      return this.initialData[this.fieldName] || this.value;
    },

    preventSetData() {
      return Object.keys(this.$attrs).includes('prevent-set-data');
    },

    selectionCount() {
      return String(this.items.filter(item => this.form[item.name] === 'on').length);
    },
  },

  watch: {
    // Handles internal model changes.
    modelValue(newVal) {
      this.$emit('input', newVal);
    },

    selectionCount(newVal) {
      this.modelValue = newVal;
    },

    // Handles external model changes.
    value(newVal) {
      this.modelValue = newVal;
    },
  },

  created() {
    this.setInitialValue();
  },

  methods: {
    ...mapActions({
      removeApiError: 'application/removeApiError',
      setData: 'application/setData',
    }),

    setInitialValue() {
      this.modelValue = this.initialValue;
    },

    async submit() { // eslint-disable-line
      this.removeApiError(this.fieldName);

      if (this.preventSetData) return;

      await this.$nextTick();

      const params = {
        [this.fieldName]: this.selectionCount,
      };

      try {
        await this.setData(params);
        this.$emit('validate-group');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.cw-checkbox-group {
  .v-input--selection-controls {
    margin-top: 0;
  }
}
</style>
